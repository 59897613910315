import React from "react";
import * as styles from "./PrivacyPolicy.module.scss";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import translate from "../../i18n/translate";

const PrivacyPolicyMain = () => {
  return (
    <InnerWrapper>
      <div className={styles.privacy}>
        <h1 className="heading">
          <span>.</span>
          {translate("privacy-title")}
        </h1>
        <p>
          {translate("privacy-text1-1")}
          <br />
          <br />
          {translate("privacy-text1-2")} <br />
          <br />
          {translate("privacy-text1-3")}
        </p>
        <h3>{translate("privacy-structure")}</h3>
        <ul className={styles.listWithRoman}>
          <li>
            <span>I.</span>
            {translate("privacy-structure1")}
          </li>
          <li>
            <span>II.</span>
            {translate("privacy-structure2")}
          </li>
          <li>
            <span>III.</span>
            {translate("privacy-structure3")}
          </li>
        </ul>
        <h2>{translate("privacy-sec1Heading")}</h2>
        <p>{translate("privacy-sec1Desc")}</p>
        <p>
          globaldatanet GmbH
          <br /> Alter Teichweg 11-13
          <br /> 22081 Hamburg
          <br /> Germany
        </p>
        <p>
          {translate("privacy-sec1Phone")} +49 40 88369785
          <br /> E-Mail: hello@globaldatanet.com
          <br /> Internet: https://globaldatanet.com
        </p>
        <h2>{translate("privacy-sec2Heading")} </h2>
        <ul>
          <li>{translate("privacy-sec2list1")}</li>
          <li>{translate("privacy-sec2list2")}</li>
          <li>{translate("privacy-sec2list3")}</li>
          <li>{translate("privacy-sec2list4")}</li>
          <li>{translate("privacy-sec2list5")}</li>
        </ul>
        <p>{translate("privacy-sec2text1")}</p>
        <h2>{translate("privacy-sec3Heading")}</h2>
        <p>{translate("privacy-sec3text1")}</p>
        <h3> {translate("privacy-sec3SocialHeading")}</h3>
        <p>
          {translate("privacy-sec3SocialText1")}
          <br />
          <br />
          {translate("privacy-sec3SocialText2")}
        </p>
        <h3>{translate("privacy-sec3SocialSubHeading")}</h3>
        <div className={styles.socialNetwork}>
          <h3>Facebook</h3>
          <p>
            Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland, a
            subsidiary of Facebook Inc., 1601 S. California Ave., Palo Alto, CA
            94304, USA.
            <br />
            <br />
            Privacy Policy:
            <a
              href="https://www.facebook.com/policy.php"
              target="_blank"
              rel="noreferrer"
            >
              https://www.facebook.com/policy.php
            </a>
            <br />
            Data Privacy Framework:
            <br />
            <a
              href="https://www.dataprivacyframework.gov/list"
              target="_blank"
              rel="noreferrer"
            >
              https://www.dataprivacyframework.gov/list
            </a>
          </p>
          <h3> LinkedIn</h3>
          <p>
            LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton Place,
            Dublin 2, Irland, a subsidiary of LinkedIn Corporation, 1000 W.
            Maude Avenue, Sunnyvale, CA 94085 USA.
            <br />
            Privacy Policy:{" "}
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              https://www.linkedin.com/legal/privacy-policy
            </a>
            <br />
            Data Privacy Framework:
            <br />
            <a
              href="https://www.dataprivacyframework.gov/list"
              target="_blank"
              rel="noreferrer"
            >
              https://www.dataprivacyframework.gov/list
            </a>
          </p>
          <h3>Twitter</h3>
          <p>
            {translate("privacy-sec3Twitter")}
            <br />
            <br />
            <a
              href="https://www.dataprivacyframework.gov/list"
              target="_blank"
              rel="noreferrer"
            >
              https://www.dataprivacyframework.gov/list
            </a>
            <br />
            {translate("privacy-sec3TwitterPolicy")}{" "}
            <a
              href="https://twitter.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              https://twitter.com/privacy
            </a>
          </p>
          <h3>Instagram</h3>
          <p>
            {translate("privacy-sec3Ig")}
            <br />
            <br />
            <a
              href="https://help.instagram.com/519522125107875F"
              target="_blank"
              rel="noreferrer"
            >
              https://help.instagram.com/519522125107875{" "}
            </a>
          </p>
          <h2>{translate("privacy-otherHeading")}</h2>
          <h3>Encharge</h3>
          <p>
            {translate("privacy-enchargeText")}
            <br />
            <br />
            <a
              href="https://encharge.io/privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              https://encharge.io/privacy-policy/{" "}
            </a>
          </p>
          <h3>{translate("privacy-googleHeading")}</h3>
          <p>
            {translate("privacy-googleText")}
            <br />
            <br />
            <a
              href="http://www.google.com/privacy.html"
              target="_blank"
              rel="noreferrer"
            >
              http://www.google.com/privacy.html{" "}
            </a>
          </p>
        </div>
      </div>
    </InnerWrapper>
  );
};

export default PrivacyPolicyMain;
