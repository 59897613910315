import React from "react";
import Layout from "../components/Layout/Layout";
import PrivacyPolicyMain from "../components/PrivacyPolicyUiComponents/PrivacyPolicyMain";
import { SEO } from "../components/seo";

const PrivacyPolicy = props => {
  return (
    <Layout location="/blog">
      <SEO
        title="Privacy Policy"
        description="Find out the rules related to personal data protection on the globaldatanet site."
        type="website"
      />
      <PrivacyPolicyMain />
    </Layout>
  );
};

export default PrivacyPolicy;
